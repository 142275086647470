<template>
    <router-link
        :to="{
            name: 'patentDetail',
            query: {
                id: info.id
            }
        }"
        class="patent"
    >
        <el-image :src="getImg(info.img)" fit="cover"></el-image>

        <div class="content">
            <div class="title">{{ info.name }}</div>
            <div class="info">
                <div class="info-item">
                    <span class="text1">申请号：</span>
                    <span class="text2">{{ info.code }}</span>
                </div>
                <div class="info-item" v-if="info.caseType == 'GENERAL'">
                    <span class="text1">申请人：</span>
                    <span class="text2">{{ info.applicant.join(',') }}</span>
                </div>
                <!-- <div class="info-item">
                    <span class="text1">专利权人：</span>
                    <span class="text2">{{ info.owner.join(',') }}</span>
                </div> -->
                <div class="info-item">
                    <span class="text1">所属领域：</span>
                    <span class="text2">{{ info.industryName }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">IPC分类号:</span>
                    <span class="text2">{{ info.ipc || '暂无' }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">价格:</span>
                    <span class="text2" v-if="info.negotiateDirectly">面议 </span>
                    <span class="text2" v-else>{{ info.expectedPrice }}万元 </span>
                </div>
                <div class="info-item">
                    <span class="text1">交易方式：</span>
                    <span class="text2">{{ getLabelName(info.tradingMethod, tradingMethodOption) }}</span>
                </div>
                <!-- <div class="info-item">
                    <span class="text1">发布时间</span>
                    <span class="text2">{{ info.applyTime }}</span>
                </div> -->
                <div class="info-item" v-if="info.caseType == 'DECLASSIFICATION'">
                    <span class="text1">发布时间:</span>
                    <span class="text2">{{ info.applyTime || '无' }}</span>
                </div>

                <!-- <div class="info-item">
                    <span class="text1">发明人：</span>
                    <span class="text2">{{ info.inventor.join(',') }}</span>
                </div> -->
                <div class="look">
                    <i class="iconfont iconfont-icon-lliulan"></i>
                    <span>{{ info.view }}个人看过</span>
                </div>
            </div>
        </div>

        <div class="edit-btn" v-if="isEdit">
            <el-button
                class="edit_information"
                v-if="info.status === 'PASS'"
                type="primary"
                size="small"
                plain
                @click.prevent="edit"
                >编辑</el-button
            >
            <el-button v-if="info.status === 'PENDING'" type="primary" size="small" plain class="under_review"
                >资料审核中</el-button
            >
            <el-button v-if="info.status === 'DENY'" type="danger" size="small" plain>已拒绝</el-button>
            <el-button size="small" plain @click.prevent="del">删除</el-button>
        </div>

        <div class="btn" v-else>
            <div class="icon-button" @click.prevent="connectKefu">
                <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                咨询
            </div>
            <!-- <el-button type="primary" @click.prevent="postMessage" class="icon-button" size="small" plain>
                <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
            </el-button> -->
        </div>
    </router-link>
</template>

<script>
import { tradingMethodOption } from '../../utils/variables';
import comEvent from '../../mixins/comEvent';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tradingMethodOption
        };
    },
    mixins: [comEvent],
    methods: {
        edit() {
            this.$router.push({
                path: '/patentEdit',
                query: {
                    caseType: this.info.caseType,
                    id: this.info.id
                }
            });
        },
        del() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/patent/del/${this.info.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$emit('refreash');
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>

<style lang="less" scoped>
.info {
    margin-top: 10px;
    .flex();
    flex-wrap: wrap;
    .info-item {
        width: 50%;
        padding-right: 50px;
        box-sizing: border-box;
        line-height: 22px;
        margin-bottom: 8px;
        .flex();

        .text1 {
            font-size: 13px;
            color: #939599;
            display: inline-block;
            min-width: 65px;
            flex-shrink: 0;
        }

        .text2 {
            font-size: 13px;
            color: #000000;
            margin-left: 10px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.under_review {
    width: 88px;
    border-radius: 2px;
    border: 1px solid #ff8700;
    background: #ffffff;
    color: #ff8700;
}
.edit_information {
    width: 88px;
    border-radius: 2px;
    border: 1px solid #01a041;
    background: #ffffff;
    color: #01a041;
}
.patent {
    padding: 20px;
    .flex();

    .el-image {
        width: 182px;
        height: 182px;
        flex-shrink: 0;
    }
    position: relative;
    cursor: pointer;
    border: 1px solid rgba(56,85,142,0.04);
    // border-bottom-color: @bg;

    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(81, 81, 81, 0.1);
        border: 1px solid #01a041;
        .text2 {
            color: #01a041;
        }
        .icon-button{
            background: #01A041;
            color: #FFFFFF;
        }
    }
}

.content {
    padding: 10px 20px 40px;
    flex-grow: 1;
    padding-right: 80px;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #292c33;
        line-height: 22px;
    }
}

.look {
    i {
        font-size: 18px;
        color: #c8c9cc;
        vertical-align: middle;
    }
    span {
        font-size: 13px;
        font-weight: 400;
        color: #818999;
        line-height: 18px;
        vertical-align: middle;
        margin: 0 3px 0 5px;
    }
    position: absolute;
    left: 220px;
    bottom: 30px;
}
.icon-button {
    width: 134px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid #01a041;
    box-sizing: border-box;
    color: #01a041;
    padding: 0px
}
.edit-btn {
    .flex();
    .el-button {
        &:first-child {
            width: 88px;
        }

        &:last-child {
            width: 56px;
        }
    }
}
</style>
