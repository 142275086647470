<template>
    <filter-list
        v-model="list"
        ref="list"
        url="/patent/all"
        :filters="filters"
        :sortKey="sortKey"
        :sortKeys="sortKeys"
        @btnEvent="addInfo"
        @init="init"
    >
        <template v-for="item in list">
            <patent-row :key="item.id" :info="item"></patent-row>
        </template>
        <patent-add ref="public" @refreash="refreash"></patent-add>
    </filter-list>
</template>

<script>
import FilterList from '../../components/page/FilterList.vue';
import { patentTypeOptions, patentStatusOptions, tradingMethodOption, moneyOptions } from '../../utils/variables';
import PatentRow from '../../components/list/PatentRow.vue';
import PatentAdd from '../../components/popup/PatentAdd.vue';
export default {
    components: { FilterList, PatentRow, PatentAdd },
    data() {
        return {
            patentTypeOptions,
            patentStatusOptions,
            tradingMethodOption,
            moneyOptions,
            fieldOptions: [], //领域,
            fieldOption: [], //领域,
            list: [],
            sortKeys: [],
            sortKey: [
                // {
                //     name: '发布时间',
                //     key: 'createdAt'
                // },
                // {
                //     name: '价格区间',
                //     key: 'expectedPrice'
                // },
                {
                    name: '浏览量',
                    key: 'view'
                }
            ]
        };
    },
    created() {
        this.init();
    },
    computed: {
        filters() {
            const type = [this.DECLASSIFICATION, this.GENERAL];
            return type[this.$route.query.caseType == 'DECLASSIFICATION' ? 0 : 1];
        },
        DECLASSIFICATION() {
            return [
                {
                    name: '所属领域',
                    key: 'industryClass',
                    list: [...this.fieldOptions]
                },
                {
                    name: '交易方式',
                    key: 'tradingMethodId',
                    list: [...this.tradingMethodOption]
                }
                // {
                //     name: '价格区间',
                //     key: 'expectedPrice',
                //     list: [...this.moneyOptions]
                // }
                // {
                //     name: '发布时间',
                //     key: 'createdAt',
                //     list: [...this.createdAtOptions]
                // }
            ];
        },
        GENERAL() {
            return [
                {
                    name: '所属领域',
                    key: 'industryClass',
                    list: [...this.fieldOption]
                },
                {
                    name: '专利类型',
                    key: 'patentTypeId',
                    list: [...this.patentTypeOptions]
                },
                {
                    name: '法律状态',
                    key: 'lawStatusId',
                    list: [...this.patentStatusOptions]
                },
                {
                    name: '交易方式',
                    key: 'tradingMethodId',
                    list: [...this.tradingMethodOption]
                },
                // {
                //     name: '价格区间',
                //     key: 'expectedPrice',
                //     list: [...this.moneyOptions]
                // },
                // {
                //     name: '发布时间',
                //     key: 'createdAt',
                //     list: [...this.createdAtOptions]
                // },
                {
                    name: '技术来源',
                    key: 'ownerType',
                    list: [...this.sourceTechnology]
                }
            ];
        },
        createdAtOptions() {
            return [
                {
                    label: '一周内',
                    value: this.getTimeBefor()
                },
                {
                    label: '一月内',
                    value: this.getTimeOnem()
                },
                {
                    label: '二月内',
                    value: this.getTimeTom()
                },
                {
                    label: '三月内',
                    value: this.getTimeTherrm()
                },
                {
                    label: '三月以上',
                    value: this.getTimeTherrms()
                }
            ];
        },
        sourceTechnology() {
            return [
                {
                    label: '高校',
                    value: 'UNIVERSITIES_AND_INSTITUTES'
                },
                {
                    label: '企业',
                    value: 'ENTERPRISES_AND_INSTITUTIONS'
                },
                {
                    label: '研究院',
                    value: 'RESEARCH'
                }
            ];
        }
    },
    methods: {
        init() {
            if (this.$route.query.caseType == 'DECLASSIFICATION') {
                this.getSetting(13, 'fieldOptions').then(() => {
                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                });
            } else {
                this.getSetting(1, 'fieldOption').then(() => {
                    this.$nextTick(() => {
                        this.$refs.list.init();
                    });
                });
            }
        },
        addInfo(value) {
            if (value) {
                // this.$refs.public.init(this.$route.query.caseType);
                this.checkLogin(false).then(() => {
                    this.$refs.public.init(this.$route.query.caseType);
                });
            }
        },
        refreash() {
            this.$refs.list.getData();
        }
    }
};
</script>

<style lang="less" scoped></style>
